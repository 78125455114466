import React, { useEffect, useRef, useState } from 'react';
import './Section_3.css';
import image_1 from './images/קטלוג_גימנובה.png';
import image_2 from './images/קטלוג_אוגאמפ.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import image_3 from './images/קטלוג_אדיוקגימ.png';
import 'swiper/css/effect-cards';
import { EffectCards, Navigation, Autoplay } from 'swiper/modules';
import i1 from './images/best/1.png';
import i2 from './images/best/2.png';

import i3 from './images/best/3.png';

import i4 from './images/best/4.png';

import i5 from './images/best/5.png';

import i6 from './images/best/6.png';

import i7 from './images/best/7.png';

import i8 from './images/best/8.png';
import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
} from 'framer-motion';

const Section_3 = () => {
  const best_immages = [i1, i2, i3, i4, i5, i6, i7, i8];
  const images = [image_1, image_2, image_3];
  const section_ref = useRef(null);
  const refs = [
    { ref_1: useRef(null) },
    { ref_2: useRef(null) },
    { ref_3: useRef(null) },
  ];
  return (
    <div className='section_3_container'>
      <div className='section_3_best_sells'>
        <span>המוצרים הנמכרים ביותר</span>
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards, Navigation, Autoplay]}
          className='best_swiper'
          spaceBetween={20}
          initialSlide={2}
          style={{
            height: '70%',
            width: '60%',
            '--swiper-navigation-color': 'black',
          }}
          navigation={true}
          loop={true}
        >
          {' '}
          {best_immages.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className='best_sells_slide'
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <motion.div
        className='section_3_katalogs'
        ref={section_ref}
        initial='initial'
        whileInView='animate'
        style={{ cursor: 'pointer' }}
      >
        {images.map((image, index) => {
          return (
            <motion.div
              onClick={() => {
                if (index === 0) {
                  window.location.href =
                    'https://it2v7.interactiv-doc.fr/html/cata_gva_2024_en_05_24_658';
                }

                if (index === 1) {
                  window.location.href =
                    'https://www.gymnova.com/media/attachment/attachment/Magazine_OJUMP-2022-EN.pdf';
                }
                if (index === 2) {
                  window.location.href =
                    'https://it2v7.interactiv-doc.fr/html/catalogue_educ_gym_2021_en_658/';
                }
              }}
              ref={refs[index]}
              key={index}
              variants={{
                initial: { opacity: 0.5, x: -window.innerWidth },
                animate: {
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.2 + 0.1 * (index + 1) },
                },
              }}
            >
              <img src={image} className='katalog_image'></img>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
};

export default Section_3;
